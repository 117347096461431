import React from "react";
import { template, setLocalStorage } from "@reco-m/core";
import { ViewComponent } from "@reco-w/core-ui";
import { Namespaces, footerModel } from "@reco-w/layout-models";
import { Row, Col } from "antd";

export namespace PageFooter {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> { }

    export interface IState extends ViewComponent.IState, footerModel.StateType { }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.footer;

        render() {
            return (
                <div className="footer">
                    <div className="footer-content">
                        <div className="container">
                            <Row>
                                <Col xs={24} sm={24} md={5}>
                                    <div className="footer-logo mt20">
                                        <img src="assets/images/home/footer-logo.png" />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={8}>
                                    <div className="contact-info mt10">
                                        <div>
                                            <i className="icon iconzuobiaofill mr5" />
                                            上海市浦东新区秋月路26号张江矽岸国际7号楼
                                        </div>
                                        <div className="mt8">
                                            <i className="icon icondianhua1 mr5" />
                                            13003223873
                                        </div>
                                        <div className="mt8">
                                            <i className="icon icon39 mr5" />
                                            ruiguyunqi@serpark.cn
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={8} sm={8} md={5}>
                                    <div className="footer-code">
                                        <img src="assets/images/home/code.png" />
                                        <div className="mt5">瑞谷云企业官微</div>
                                    </div>
                                </Col>
                                <Col xs={16} sm={16} md={6}>
                                    <Row className="mt12 footer-nav" gutter={[16, 16]}>
                                        <Col span={12} onClick={() => {
                                            setLocalStorage("selectedMenu", '/entrust')
                                            this.goTo('/entrust')
                                        }}>
                                            <div className="footer-nav-item">委托招商</div>
                                        </Col>
                                        <Col span={12} onClick={() => {
                                            setLocalStorage("selectedMenu", 'selection')
                                            this.goTo('/selection')
                                        }}>
                                            <div className="footer-nav-item">企业选址</div>
                                        </Col>
                                        <Col span={12} onClick={() => {
                                            setLocalStorage("selectedMenu", 'aliyun')
                                            this.goTo('/aliyun')
                                        }}>
                                            <div className="footer-nav-item">阿里云特惠</div>
                                        </Col>
                                        <Col span={12} onClick={() => {
                                            setLocalStorage("selectedMenu", 'aboutus')
                                            this.goTo('/aboutus')
                                        }}>
                                            <div className="footer-nav-item">联系我们</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="footer-desc">
                        <div className="container">
                            <span>Copyright © 2019-2022 瑞谷云企官网</span>
                            <a href="https://beian.miit.gov.cn/" target="_blank" className="ml25">备案号：沪ICP备15022387号-4</a>
                            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502012894" className="ml25">
                                <img src={"assets/images/bean.png"} className="mr5" />沪公网安备 31011502012894号</a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.footer]);
}
